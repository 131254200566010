import React, { useRef } from "react"
import Flippy, { FrontSide, BackSide } from "react-flippy"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const FlipCarousel = ({ item }) => {
  const ref = useRef()
  return (
    <>
      <div className="flipCarousel mobile container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Carousel
              statusFormatter={(current, total) => `${current} / ${total}`}
              centerMode={true}
              showThumbs={false}
              showArrows={true}
              centerSlidePercentage={90}
            >
              {item.map((val, i) => (
                <div key={val.id + i}>
                  <Flippy
                    flipOnHover={false} // default false
                    flipOnClick={true} // default false
                    flipDirection="horizontal" // horizontal or vertical
                    ref={ref} // to use toggle method like ref.curret.toggle()
                  >
                    <FrontSide className={val.background}>
                      <div className="front-content">
                        <h3>{val.question}</h3>
                      </div>
                    </FrontSide>
                    <BackSide>
                      <div className="back-content">
                        <p>{val.answer}</p>
                        {val?.bulletPoints && (
                          <ul className="fa-ul">
                            {val.bulletPoints.map((point, i) => (
                              <li key={i}>
                                <span className="eb-green fa-li">
                                  <i className="square-check"></i>
                                </span>
                                {point}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </BackSide>
                  </Flippy>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default FlipCarousel
