import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FilterData from "../components/filterData"
import FlipCard from "../components/flipCard"
import FlipCarousel from "../components/flipCarousel"
import contractorPDF from "../pdf/eb-home-improvement-lender.pdf"
import pdffile from "../images/pdf.svg"

const RegionsEnerbankDifference = () => {
  const item = FilterData

  // const menuItems = [...new Set(FilterData.map((Val) => Val.category))];

  // const filterItem = (curcat) => {
  //   const newItem = FilterData.filter((newVal) => {
  //     return newVal.category === curcat;
  //   });
  //   setItem(newItem);
  // };
  const DownloadFile = url => {
    window.open(url, "_blank")
  }

  return (
    <Layout>
      <Seo
        title={"The Regions | EnerBank Difference"}
        description={
          "The top 16 things you should know about your home improvement lender (but were afraid to ask)"
        }
      />
      <Helmet>
        <body className="regions-eb-difference" />
      </Helmet>
      <section
        className="banner-area editable-page-hero-banner"
        id="faq-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/faq-hero-img.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Everything You Should Know About Your Home Improvement Lender (But
              Were Afraid To Ask)
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          {/* The intro content will change depending if viewed by contractor or program sponsor */}
          <div className="justify-content-center">
            <p>
              When you&#39;re looking for a loan provider to handle the
              financing your dealer network offers to homeowners for funding
              their home improvement projects, there are a few things you need
              to think about.{" "}
            </p>
            <p>
              <i>
                <b>
                  <span className="desktop">Click</span>
                  <span className="mobile">Tap</span> on the card to learn more.
                </b>
              </i>
            </p>
            <p>
              <button
                className="pdffile"
                onClick={() => DownloadFile(contractorPDF)}
              >
                <img src={pdffile} alt="download PDF file icon" /> Download a
                PDF version
              </button>
            </p>
          </div>
        </div>
      </section>
      {/* <section className="filter-buttons">
        <div className="container">
          <div className="row justify-content-center">
            <FilterButtons
              filterItem={filterItem}
              setItem={setItem}
              menuItems={menuItems}
            />
          </div>
        </div>     
      </section> */}
      <section className="flip-cards">
        <div className="container">
          <div className="row">
            <FlipCard item={item} />
            <FlipCarousel item={item} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RegionsEnerbankDifference
