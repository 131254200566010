const FilterData = [
  {
    id: "1",
    question: "Home Improvement Specialty",
    answer:
      "We have over two decades of deep experience working side-by-side in the home improvement trenches with contractors of all kinds. For many lenders, home improvement loans have always just been one more thing they do. We've been keyed into your specific business for a long time and have built products and services that are specific to your needs.",
    category: "Program Sponsor & Contractors",
    background: "regions-green",
  },
  {
    id: "2",
    question: "A Reliable Financial Services Provider",
    answer:
      "Some home improvement loan providers are non-bank consumer finance companies that merely pass your information through to a bank. But as an actual bank that's fully regulated, you can count on us for a stable long-term relationship.",
    category: "Program Sponsor & Contractors",
    background: "bg-blue",
  },
  {
    id: "3",
    question: "Once Your Customer's Loan is Approved, You're Good to Go",
    answer:
      "Your new lending partner should be well-capitalized. While it may not be the case with some of our competitors, we always have the capital we need, because we're part of the Regions Financial family &#8211; a full-service bank. This also helps us keep approval rates consistent.",
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
  {
    id: "4",
    question: "Approved Customers Are Happy Customers",
    answer:
      "High approval rates means fewer lost deals. We approve about 4 out of 5 homeowner loan applications.",
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
  {
    id: "5",
    question: "Attractive Home Improvement Focused Loans",
    answer:
      "As a leader in the development of innovative consumer payment options, our independent contractors have access to a variety of products for their customers, and we work with our program sponsors to develop program-specific product mixes that work best for their business model.",
    category: "Program Sponsor & Contractors",
    background: "regions-green",
  },
  {
    id: "6",
    question: "No Hidden Fees",
    answer:
      "Our contractor fees are competitive. What you see is what you get.",
    category: "Program Sponsor & Contractors",
    background: "bg-blue",
  },
  {
    id: "7",
    question: "No Double-Dipping",
    answer:
      "Your provider really should have an integrated counter-offer — a loan option for less-creditworthy homeowners that doesn't require a second credit pull or clunky hand-off to a third party. We do it all in-house, as a seamless part of the paperless application process.",
    category: "Contractors",
    background: "bg-blue",
  },
  {
    id: "8",
    question: "U.S.-Based Customer Service",
    answer:
      "Make sure your lending provider has U.S. associates to meet your homeowner customer service and contractor support needs.",
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
  {
    id: "9",
    question: "Continuous Improvement",
    answer:
      "Make sure your home improvement lender is not only current with today's lending technology — from credit decision engines to application methods and funding protocols — but is always looking ahead and paving the way for tomorrow's best practices.",
    category: "Contractors",
    background: "regions-green",
  },
  {
    id: "10",
    question: "Quick and Easy Applications",
    answer:
      "Make sure your loan provider offers multiple paperless application methods, making it a breeze for homeowners to apply. We currently offer a Mobile Loan App, Online Loan Application, Loan-by-Phone, and API integration with popular platforms. ",
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
  {
    id: "11",
    question: "Powerful Account Management",
    answer:
      "Our online PartnerPortal provides a comprehensive dashboard with real-time data and reporting for program sponsors and contractors to effectively monitor and manage their payment options programs – right down to the individual customer level. Make sure your loan provider keeps it all out in the open for you.",
    category: "Program Sponsor & Contractors",
    background: "regions-green",
  },
  {
    id: "12",
    question: "Branded For Your Business",
    answer:
      "A full-service sponsored loan program necessarily includes the option to white-label the program, so you can grow your brand and dominate the market, while someone else does the financial heavy lifting. When the loan program has your name on it, you further cement your reputation as a one-stop shop for every home improvement need. And we make you look good.",
    category: "Program Sponsor",
    background: "bg-blue",
  },
  {
    id: "13",
    question: "Education to Help You Succeed",
    answer:
      "Be sure that the loan provider you choose doesn't bring you aboard then leave you high and dry, wondering how it all works. Our training department professionals provide live and on-demand orientation that's customized to your specific role in the company — owner, salesperson, or admin. We'll show you how it works and offer proven best practices for making your loan program work for you.",
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
  {
    id: "14",
    question: "Personal Service",
    answer:
      "Doesn't it drive you crazy to have to explain yourself every time you call in? Not here. We provide you a dedicated relationship manager — someone who knows your business, and knows you — so you can receive consistent, high-quality service whenever you call in for help with growing your business. We also have a contractor support line available during hours that make sense for contractors.",
    category: "Program Sponsor & Contractors",
    background: "bg-blue",
  },
  {
    id: "15",
    question: "Marketing Materials",
    answer:
      "Did we mention you're also not alone when it comes to getting the word out? When your program launches, we provide a full suite of supporting marketing tools to help you succeed from the get-go. It's like having your own marketing agency at your fingertips. Make sure your lender of choice can do that for you.",
    category: "Program Sponsor & Contractors",
    background: "regions-green",
  },
  {
    id: "16",
    question: "More Than Just a Lender",
    answer:
      "A true lending partner in your home improvement finance plan should go the extra mile by offering more. Like:",
    bulletPoints: [
      "Professional marketing collateral",
      "Best practices instruction",
      "Guidance for advertising compliance",
      "Personalized support",
    ],
    category: "Program Sponsor & Contractors",
    background: "bg-charcoal",
  },
]

export default FilterData
