import React, { useRef } from "react"
import Flippy, { FrontSide, BackSide } from "react-flippy"

const FlipCard = ({ item }) => {
  const ref = useRef()
  return (
    <>
      <div className="flipCards desktop container-fluid">
        <div className="row justify-content-center">
          {item.map(val => (
            <div className="col-md-4 col-sm-6 my-3 py-3 border-0" key={val.id}>
              <Flippy
                flipOnHover={false} // default false
                flipOnClick={true} // default false
                flipDirection="horizontal" // horizontal or vertical
                ref={ref} // to use toggle method like ref.curret.toggle()
              >
                <FrontSide className={val.background}>
                  <div className="front-content">
                    <h3>{val.question}</h3>
                  </div>
                </FrontSide>
                <BackSide>
                  <div className="back-content">
                    <p>{val.answer}</p>
                    {val?.bulletPoints && (
                      <ul className="fa-ul">
                        {val.bulletPoints.map((point, i) => (
                          <li key={i}>
                            <span className="eb-green fa-li">
                              <i className="square-check"></i>
                            </span>
                            {point}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </BackSide>
              </Flippy>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default FlipCard
